// @ts-check

/**
 * @param {{ id: string, path: string, contentId: string, index: number, ignoreList?: string[] }} param
 * @returns {Promise<string | undefined>}
 */
const resolvePoofWidget = async ({ id, path, contentId, index, ignoreList }) => {
  const response = await fetch("/api/resolve-poof-content/", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      path,
      contentId,
      index,
      ignoreList,
    }),
  });

  if (response.ok) {
    return await response.text();
  }

  return;
};

export default resolvePoofWidget;
