import {
  EventNames,
  EventTriggers,
  pushEvent,
  getContent,
} from "../../tracking.js";

const TAG_NAME = "gen-article-wrapper";

class ArticleWrapper extends HTMLElement {
  constructor() {
    super();

    this.initObserver = this.initObserver.bind(this);
    this.handleOnObserve = this.handleOnObserve.bind(this);
  }

  initProps() {
    const meta = this.getAttribute("meta");
    const isInitialArticle = this.hasAttribute("initial");

    this.props = {
      isInitialArticle,
      path: this.getAttribute("path"),
      meta: meta ? JSON.parse(meta) : null,
    };
  }

  initState() {
    this.state = {
      trackingTrigger: this.props.isInitialArticle ? (
        EventTriggers.PageLoad
      ) : (
        EventTriggers.VirtualPageLoad
      ),
    };
  }

  initObserver() {
    const trackerElement = document.createElement("div");
    trackerElement.setAttribute("class", "article-wrapper__visibility-tracker");
    this.append(trackerElement);
    this.observer = new IntersectionObserver(this.handleOnObserve, { threshold: 0.99 });

    this.observer.observe(trackerElement);
  }

  connectedCallback() {
    this.initProps();
    this.initState();
    this.initObserver();
  }

  isCurrentTrackedArticle() {
    const lastPageViewEvent = window.dataLayer.reverse().find((item) => item.event === EventNames.PageView);

    if (
      lastPageViewEvent &&
      lastPageViewEvent.content.id &&
      lastPageViewEvent.content.id.value === this.props.meta.contentId
    ) {
      return true;
    }
  }

  /**
   * @type {IntersectionObserverCallback}
   */
  handleOnObserve([ entry ]) {
    if (entry.isIntersecting && !this.isCurrentTrackedArticle()) {
      this.updateMeta();
      this.sendTracking();
    }
  }

  updateMeta() {
    if (this.props.path) {
      let path = this.props.path;

      if (!path.startsWith("/")) {
        path = `/${path}`;
      }

      if (!path.endsWith("/")) {
        path = `${path}/`;
      }

      if (window.location.pathname !== path) {
        window.history.pushState({}, "", path);
      }
    }

    if (this.props.meta) {
      document.title = this.props.meta.title;
    }
  }

  sendTracking() {
    if (this.props.meta) {
      pushEvent({
        ...((window.generika && window.generika.trackingData) || {}),
        event: EventNames.PageView,
        content: {
          ...getContent(this.props.meta),
          trigger: this.state.trackingTrigger,
        },
      });

      // Any event after the initial load has been tracked is virtual:
      this.state.trackingTrigger = EventTriggers.VirtualPageLoad;
    }
  }
}

export {
  TAG_NAME,
  ArticleWrapper,
};
