// @ts-check
/* eslint-disable no-console */

/** @type {import('./types.js').PhotoSwipeLightbox} */
let PhotoSwipeLightbox;
/** @type {import('./types.js').PhotoSwipeDynamicCaptionPlugin} */
let PhotoSwipeDynamicCaptionPlugin;
/** @type {HTMLLinkElement} */
let photoswipeStyle;

const TAG_NAME = "gen-photoswipe";

class PhotoSwipe extends HTMLElement {
  async connectedCallback() {
    try {
      await this.loadLightbox();
      await this.loadCaptionPlugin();
      this.loadStyle();

      if (this.lightbox) {
        this.setAttribute("active", "");
        this.lightbox.init();
      }
    } catch (error) {
      console.error("Something went wrong while loading PhotoSwipe:", error);
    }
  }

  async loadLightbox() {
    if (!PhotoSwipeLightbox) {
      PhotoSwipeLightbox = (await import("./lightbox.js")).default;
    }

    const localizations = (window.generika && window.generika.localizations && window.generika.localizations.photoswipe) || {};

    const {
      closeTitle,
      zoomTitle,
      arrowPrevTitle,
      arrowNextTitle,
      indexIndicatorSep,
    } = localizations;

    const lightbox = new PhotoSwipeLightbox({
      gallery: this,
      children: ".photoswipe-link",
      bgOpacity: 1,
      closeTitle,
      zoomTitle,
      arrowPrevTitle,
      arrowNextTitle,
      indexIndicatorSep,
      pswpModule: () => import("./photoswipe-core.js"),
    });

    lightbox.addFilter("placeholderSrc", (placeholderSrc, slide) => {
      const element = /** @type {HTMLImageElement} */(slide.element);
      if (element) {
        return element.currentSrc || element.src;
      }
      return placeholderSrc;
    });
    lightbox.on("beforeOpen", this.prepareDOM.bind(this));
    lightbox.on("close", this.cleanupDOM.bind(this));
    this.lightbox = lightbox;
  }

  async loadCaptionPlugin() {
    if (!PhotoSwipeDynamicCaptionPlugin) {
      // @ts-ignore
      PhotoSwipeDynamicCaptionPlugin = (await import("./caption-plugin.js")).default;
    }

    this.captionPlugin = new PhotoSwipeDynamicCaptionPlugin(this.lightbox, {
      type: "below",
      captionContent: (/** @type {{data: {element: HTMLElement}}} */slide) => slide.data.element.dataset.pswpCaption,
    });
  }

  prepareDOM() {
    const others = document.querySelectorAll("body > *:not(.pswp):not(script):not(style)");
    for (const other of others) {
      other.setAttribute("inert", "");
      this.madeInert.add(other);
    }
    this.lightbox.on("bindEvents", () => {
      // Helper class name to facilitate testing.
      this.lightboxElement.classList.add("pswp--events-ready");
      this.lightboxElement.setAttribute("aria-modal", "true");
      this.lightboxElement.focus();
    });

    this.lightbox.on("contentActivate", ({ content }) => {
      this.dispatchEvent(new CustomEvent("slidechange", { detail: { index: content.index }, bubbles: true }));
    });
  }

  cleanupDOM() {
    for (const other of this.madeInert) {
      other.removeAttribute("inert");
    }
    this.madeInert.clear();
    this.lightboxElement.removeAttribute("aria-modal");
    window.requestAnimationFrame(() => {
      const currentSlideOpener = this.currentSlideOpener;
      if (currentSlideOpener) {
        currentSlideOpener.focus();
      }
    });
  }

  get madeInert() {
    if (!this._madeInert) {
      /** @type {Set<Element>} */
      this._madeInert = new Set();
    }
    return this._madeInert;
  }

  get lightbox() {
    if (!this._lightbox) {
      throw new Error("Lightbox is not initialized yet");
    }
    return this._lightbox;
  }

  /**
   * @param {InstanceType<PhotoSwipeLightbox>} lightbox
   */
  set lightbox(lightbox) {
    this._lightbox = lightbox;
  }

  get pswpInstance() {
    if (!this.lightbox.pswp) {
      throw new Error("PhotoSwipe core not initialized yet");
    }
    return this.lightbox.pswp;
  }

  get lightboxElement() {
    if (!(this.pswpInstance && this.pswpInstance.element)) {
      throw new Error("Lightbox element is not initialized yet");
    }
    return this.pswpInstance.element;
  }

  get currentSlideOpener() {
    if (!(this.pswpInstance && this.pswpInstance.currSlide)) {
      throw new Error("No current slide yet");
    }
    return this.pswpInstance.currSlide.data.element;
  }

  loadStyle() {
    if (!photoswipeStyle) {
      photoswipeStyle = document.createElement("link");
      photoswipeStyle.rel = "stylesheet";
      photoswipeStyle.href = window.generika.assets.photoswipe;
      document.head.append(photoswipeStyle);
    }
  }
}

export {
  TAG_NAME,
  PhotoSwipe,
};
